<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
			<el-table
			    :data="list"
				:span-method="arraySpanMethod"
			    border
			    style="width: 100%">
			    <el-table-column
			      prop="nianji"
			      label="年级号"
				  align="center">
			    </el-table-column>
			    <el-table-column
			      prop="number"
			      label="序号"
				  align="center">
			    </el-table-column>
			    <el-table-column
			      prop="t_code"
				  align="center"
			      label="教师编码（饭卡号）"
				  >
			    </el-table-column>
				<el-table-column
				  prop="t_name"
				  align="center"
				  label="教师姓名">
				</el-table-column>
				<el-table-column
				  prop="gxkj"
				  align="center"
				  label="共享课件">
				</el-table-column>
				<el-table-column
				  prop="gkkks"
				  align="center"
				  label="公开课开设">
				</el-table-column>
				<el-table-column
				  prop="mt"
				  align="center"
				  label="命题">
				</el-table-column>
				<el-table-column
				  prop="tkjl"
				  align="center"
				  label="听课记录">
				</el-table-column>
				<el-table-column
				  prop="jzbk"
				  align="center"
				  label="集智备课">
				</el-table-column>
				<el-table-column
				  prop="bkz_score"
				  align="center"
				  label="备课组赋分">
				</el-table-column>
				<el-table-column
				  prop="bkz_init_score"
				  align="center"
				  label="考核初始得分">
				</el-table-column>
				<el-table-column
				  prop="jwc_score"
				  align="center"
				  label="教务处考核最终得分（大于等于10分的都给10分，其它不变。）"
				  >
				</el-table-column>
			  </el-table>
		</el-card>
	</div>
</template>

<script>
	import common from "@/common/mixins/common.js"
	import Breadcrumb from '@/components/Breadcrumb';
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			Breadcrumb,
		},
		data() {
			return {
				preUrl: 'jtbk',
				autoRequest: false,
				id:'',
				s_id:'',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				data:{},
				list:[],
				list_length:0,
			}
		},
		mounted() {
			if(this.$route.query.id){
				this.id = this.$route.query.id
			}
			if(this.$route.query.id){
				this.s_id = this.$route.query.s_id
			}
			this.getData()
		},
		computed:{
			
		},
		methods:{
			getData(){
				this.axios.get('/manage/'+this.preUrl+'/view',{
					params:{
						id:this.id
					}
				}).then(res=>{
					if(res.status){
						this.data = res.data
						this.list = res.data.list
						this.list_length = this.list.length
						this.list.push({
							nianji:'合计',
							number:'人数',
							t_code:'',
							t_name:'',
							gxkj:'',
							gkkks:'',
							mt:'',
							tkjl:'全组考核赋分合计',
							jzbk:'全组得分合计',
							bkz_score:'教务处考核分合计',
							bkz_init_score:'',
							jwc_score:'',
						})
						this.list.push({
							nianji:this.data.people_num,
							number:'',
							t_code:'',
							t_name:'',
							gxkj:'',
							gkkks:'',
							mt:this.data.qz_score,
							tkjl:this.data.qz_score_total,
							jzbk:this.data.jwc_kh_score,
							bkz_score:'',
							bkz_init_score:'',
							jwc_score:'',
						})
						this.list.push({
							nianji:'总分（人数×8）',
							number:this.data.score_total,
							t_code:'',
							t_name:'',
							gxkj:'',
							gkkks:'',
							mt:'',
							tkjl:'',
							jzbk:'',
							bkz_score:'',
							bkz_init_score:'',
							jwc_score:'',
						})
						this.list.push({nianji:'“得分”=组内个人考核分数÷全组考核分数合计×总分',})
					}
				})
			},
			arraySpanMethod({ row, column, rowIndex, columnIndex }) {
				if(rowIndex === this.list_length){
					if(columnIndex === 0){
						return [2, 3];
					}
				}
				if(rowIndex === this.list_length+2){
					if(columnIndex === 0){
						return [1, 9];
					}
					if(columnIndex === 1){
						return [1, 7];
					}
				}
				if(rowIndex === this.list_length+3){
					if(columnIndex === 0){
						return [1, 20];
					}
				}
			},
		}
	}
</script>

<style>
</style>